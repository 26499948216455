body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: black;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.links {
  position: absolute;
  z-index: 1200;
  padding: 64px;
  margin: 16px;
  border: 1px solid white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: 400ms;
}

.links:hover {
  box-shadow: 0px 0px 10px 4px rgba(255, 255, 255, 0.5);
}

.image {
  position: fixed;
  left: 50%;
  margin-left: -250px;
  top: 50%;
  margin-top: -156.6px;
}

a {
  color: white;
  font-size: 24px;
}

.heading {
  color: black;
  position: fixed;
  z-index: 10;
  font-size: 32px;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
